import React, { useEffect, useState } from "react";
import { useSelector, connect, useDispatch } from "react-redux";
import { fetchMetrics } from "../state";
import { GlobalStyle } from "../style/globalStyles";
import { Layout } from "../components/Layout";
import DateTime from "react-datetime";
import Moment from "moment";
import "react-datetime/css/react-datetime.css";

import { DateWrapper1, DateWrapper2 } from "../style";
import { RidesSection as MetricsSection } from "../style/rides";
import { MetricsTable } from "../components/MetricsTable";
import { BallBeat } from "react-pure-loaders";
import { PaymentForm as MetricsForm } from "../style";
import Chart from "chart.js";
import { MetricsStats } from "../components/MetricsStats";
import { array } from "prop-types";
function MetricsContainer({ metricsData, metrics }) {
  const dispatch = useDispatch();

  const loginData = useSelector((state) => state.auth.user);
  const loginBool = Boolean(loginData.portalUserId);

  useEffect(() => {
    if (!loginBool) {
      navigate("/login");
    }
    // if (metricsData.businesses.length === 0) {
    //   fetchMetrics();
    // }
    // if (metrics.length > 0) console.log(occuranceCount(metrics, "city"));
  }, [metrics]);
  // console.log(state, ridesFlag);

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const sorter = (a, b) => {
    if (a[0].split(" ")[1] !== b[0].split(" ")[1]) {
      return a[0].split(" ")[1] - b[0].split(" ")[1];
    } else {
      return (
        months.indexOf(a[0].split(" ")[0]) - months.indexOf(b[0].split(" ")[0])
      );
    }
  };
  function result(array) {
    let obj = {};
    array.forEach(function (nestedArray) {
      obj = { ...obj, [nestedArray[0]]: nestedArray[1] };
      return obj;
    });
    return obj;
  }

  var metArr = [
    { key: "Ride Month", value: "ride_start_date" },
    { key: "City", value: "city" },
    { key: "Trip Type", value: "trip_type" },
    { key: "Category", value: "category" },
    { key: "Order Source", value: "order_from" },
    { key: "User Type", value: "user_type" },
  ];
  var fArr = {};
  var occuranceCount = () => {
    return metArr.map((list) => {
      return {
        ...fArr,
        [list.key]:
          list.value === "ride_start_date"
            ? result(
                Object.entries(
                  metrics.reduce(
                    (acc, o) => (
                      (acc[
                        Moment(
                          o[list.value].split(" ")[0],
                          "DD-MM-YYYY"
                        ).format("MMM YYYY")
                      ] =
                        (acc[
                          Moment(
                            o[list.value].split(" ")[0],
                            "DD-MM-YYYY"
                          ).format("MMM YYYY")
                        ] || 0) + 1),
                      acc
                    ),
                    {}
                  )
                ).sort(sorter)
              )
            : metrics.reduce(
                (acc, o) => (
                  (acc[o[list.value]] = (acc[o[list.value]] || 0) + 1), acc
                ),
                {}
              ),
      };
    });
  };
  // console.log(occuranceCount());
  var Dates = metrics.reduce(
    (acc, o) => (
      (acc[o.ride_start_date.split(" ")[0]] =
        (acc[o.ride_start_date.split(" ")[0]] || 0) + 1),
      acc
    ),
    {}
  );
  // console.log(Dates);
  // console.log(
  //   Moment(o.ride_start_date.split(" ")[0], "DD-MM-YYYY").format("MMM YYYY")
  // );
  var datesI = metrics.reduce(
    (acc, o) => (
      (acc[
        Moment(o.ride_start_date.split(" ")[0], "DD-MM-YYYY").format("MMM YYYY")
      ] =
        (acc[
          Moment(o.ride_start_date.split(" ")[0], "DD-MM-YYYY").format(
            "MMM YYYY"
          )
        ] || 0) + 1),
      acc
    ),
    {}
  );

  // .sort(sorter);
  // console.log(Object.entries(datesI));

  // console.log(Object.entries(datesI).sort(sorter));
  // console.log(result(Object.entries(datesI).sort(sorter)));
  // var orderSource = metrics.reduce(
  //   (acc, o) => ((acc[o.order_from] = (acc[o.order_from] || 0) + 1), acc),
  //   {}
  // );
  // var userType = metrics.reduce(
  //   (acc, o) => ((acc[o.user_type] = (acc[o.user_type] || 0) + 1), acc),
  //   {}
  // );

  // console.log(
  //   "User Type- ",
  //   userType,
  //   "Order Source- ",
  //   orderSource,
  //   "Category- ",
  //   category,
  //   "Cities- ",
  //   cities
  // );

  // console.log(Object.keys(cities), Object.values(cities));

  const [dateIni, setDateIni] = useState(
    {
      startDate: Moment(new Date(), "DD-MM-YYYY")
        .subtract(1, "months")
        .format("DD-MMM-YYYY"),
      endDate: Moment(new Date(), "DD-MM-YYYY").format("DD-MMM-YYYY"),
    }
    // "Select date"
  );

  const [metricsInput, setMetricsInput] = useState({
    startDate: Moment(dateIni.startDate, "DD-MMM-YYYY").format(
      "YYYY-MM-DD 00:00:00"
    ),
    endDate: Moment(dateIni.endDate, "DD-MMM-YYYY").format(
      "YYYY-MM-DD 00:00:00"
    ),
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    if (metricsInput.startDate && metricsInput.endDate) {
      dispatch(fetchMetrics(metricsInput));
    } else {
      alert("Please check/ fill the inputs required");
      return;
    }
  };

  return (
    <>
      <GlobalStyle />

      <Layout>
        <MetricsSection>
          <h2>Metrics</h2>
          <br />
          <MetricsForm>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "start",
                width: "fit-content",
              }}
            >
              <label>
                <DateWrapper1>
                  <DateWrapper2>
                    From Date
                    <span>
                      <DateTime
                        value={dateIni.startDate}
                        dateFormat="DD-MMM-YYYY"
                        timeFormat={false}
                        onChange={(date) => {
                          setMetricsInput({
                            ...metricsInput,
                            startDate: Moment(date, "DD-MM-YYYY").format(
                              "YYYY-MM-DD 00:00:00"
                            ),
                          });
                          setDateIni({
                            ...dateIni,
                            startDate: Moment(date, "DD-MM-YYYY").format(
                              "DD-MMM-YYYY"
                            ),
                          });
                        }}
                        className="dateTime"
                      />
                    </span>
                  </DateWrapper2>
                </DateWrapper1>
              </label>
              <label>
                <DateWrapper1>
                  <DateWrapper2>
                    To Date
                    <span>
                      <DateTime
                        value={dateIni.endDate}
                        dateFormat="DD-MMM-YYYY"
                        timeFormat={false}
                        onChange={(date) => {
                          setMetricsInput({
                            ...metricsInput,
                            endDate: Moment(date, "DD-MM-YYYY").format(
                              "YYYY-MM-DD 00:00:00"
                            ),
                          });
                          setDateIni({
                            ...dateIni,
                            endDate: Moment(date, "DD-MM-YYYY").format(
                              "DD-MMM-YYYY"
                            ),
                          });
                        }}
                        className="dateTime"
                      />
                    </span>
                  </DateWrapper2>
                </DateWrapper1>
              </label>
              <button
                style={{
                  width: "fit-content",
                  height: "fit-content",
                  backgroundColor: "seagreen",
                  color: "white",
                  border: "none",
                  padding: ".5rem",
                  cursor: "pointer",
                }}
                onClick={handleSubmit}
              >
                Get Metrics
              </button>
            </div>
          </MetricsForm>
          {metricsData.loadingmetrics ? (
            <h3>
              Loading Metrics{" "}
              <span>
                <BallBeat
                  color={"#123abc"}
                  loading={metricsData.loadingmetrics}
                />
              </span>{" "}
            </h3>
          ) : metricsData.errormetrics ? (
            <>
              <h3>ERROR ⚠️ FETCHING Metrics</h3>
              <br />
              <p style={{ color: "grey" }}>{metricsData.errormetrics}</p>
              <br />
              <br />
            </>
          ) : (
            <>
              {metrics.length > 0 ? (
                <>
                  {/* <RefundReportStats
                    refundreport={refundreport}
                    vendor={metricsData.vendor}
                  /> */}
                  {/* <CitiesPieChart /> */}

                  <MetricsStats metricStats={metrics && occuranceCount()} />
                  <MetricsTable metrics={metrics} />
                </>
              ) : (
                "No self drive refund report found ⚠️"
              )}
            </>
          )}
        </MetricsSection>
      </Layout>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    metricsData: state.vendors,
    metrics: state.vendors.metrics,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // fetchMetrics: (metricsInput) => dispatch(fetchMetrics(metricsInput)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MetricsContainer);

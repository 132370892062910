import React from "react";
import { Table } from "./Table";
import { TableStyles } from "../style/globalStyles";
import { exportCSVFile } from "../../jsonToCsvDownload";
export const MetricsTable = ({ metrics }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "Serial No",
        id: "row",
        filterable: false,
        // width: 35,
        accessor: (item) => {
          var rowNum = metrics.indexOf(item) + 1;
          // console.log(rowNum);
          return rowNum;
        },
      },
      {
        Header: "New Users",
        accessor: "newUsers",
        // width: 35,
      },

      {
        Header: "Bid Id",
        accessor: "bid_id",
        // width: 55,
      },

      {
        Header: "City",
        accessor: "city",
      },
      {
        Header: "Created",
        accessor: "created",
      },
      //   {
      //     Header: "Ride Start Date",
      //     accessor: "ride_start_date",
      //   },
      //   {
      //     Header: "Ride End Date",
      //     accessor: "ride_end_date",
      //   },

      {
        Header: "Trip Type",
        accessor: "trip_type",
      },
      {
        Header: "Category",
        accessor: "category",
      },
      {
        Header: "Order Origin",
        accessor: "order_from",
      },
      {
        Header: "User Type",
        accessor: "user_type",
      },
    ],
    []
  );

  var data = React.useMemo(() => metrics, []);

  var tableRender = (
    <Table
      getTdProps={(state, rowInfo, column, instance) => {
        return {
          // onClick: (e) => console.log("row clicked", rowInfo),
        };
      }}
      // Pass the page url here to navigate to onClick
      //   path={"/ride"}
      // pass filename to enable download .csv
      filename={""}
      extras={true}
      columns={columns}
      data={data}
    />
  );

  return (
    <>
      <TableStyles>{metrics ? tableRender : " "}</TableStyles>
    </>
  );
};

import React, { useEffect, useState } from "react";
import "chartjs-plugin-colorschemes";
// import "chartjs-plugin-colorschemes/src/plugins/plugin.colorschemes";
// import "chartjs-plugin-colorschemes/src/colorschemes/colorschemes.office";
import { CanvasContainer } from "../style/globalStyles";
export const MetricsStats = ({ metricStats }) => {
  // console.log(cities, category, userType, orderSource);
  // console.log(metricStats);
  var chartCanvases = metricStats.map((obj) => (
    <canvas
      key={Object.keys(obj)[0]}
      id={Object.keys(obj)[0].split(" ").join("")}
      // style={{ width: "400px", height: "400px" }}
    ></canvas>
  ));
  function dynamicColors() {
    var r = Math.floor(Math.random() * 255);
    var g = Math.floor(Math.random() * 255);
    var b = Math.floor(Math.random() * 255);
    return "rgba(" + r + "," + g + "," + b + ", 0.5)";
  }
  function poolColors(a) {
    var pool = [];
    for (var i = 0; i < a; i++) {
      pool.push(dynamicColors());
    }
    return pool;
  }
  const data = ({ d, n }) => {
    // console.log(obj);
    return {
      labels: Object.keys(d),
      datasets: [
        {
          label: n,
          data: Object.values(d),
          backgroundColor: poolColors(Object.keys(d).length),
          maxBarThickness: 40,
          borderWidth: 0,
          hoverBorderWidth: 2,
        },
      ],
      // These labels appear in the legend and in the tooltips when hovering different arcs
    };
  };

  var createOptions = ({ o, t }) => {
    return {
      title: {
        display: true,
        text: t,
        fontSize: 15,
      },
      plugins: {
        colorschemes: {
          scheme: "office.Excel16",
        },
      },
      responsive: false,
    };
  };

  var createCanvasElement = ({ forData, name, extraOptions, chartType }) => {
    // console.log(name, forData);
    // create a new canvas tag
    // var canvEle = document.createElement("canvas");
    // canvEle.id = name;
    // canvEle.width = "30vw";
    // canvEle.height = "30vh";

    var ctx =
      typeof document !== `undefined` &&
      document.getElementById(name.split(" ").join("")).getContext("2d");

    // console.log(ctx);
    const [fromArgs] = new Chart(ctx, {
      type:
        name === "City"
          ? "bar"
          : name === "Category" || name === "Trip Type"
          ? "pie"
          : name === "Ride Month"
          ? "line"
          : "doughnut",
      data: data({ d: forData, n: name }),
      options: createOptions({ o: extraOptions, t: name }),
    });
  };
  const createChartGraph = (metricStatsArray) => {
    // console.log(metricStatsArray);
    // console.log(Object.keys(metricStatsArray));

    metricStatsArray.forEach((ms) => {
      // console.log(ms);
      var inputData = {
        name: Object.keys(ms)[0],
        forData: Object.values(ms)[0],
        extraOptions: {},
        // chartType: "bar",
      };
      // console.log(inputData);
      createCanvasElement(inputData);
    });
  };
  // console.log(chartCanvases);

  useEffect(() => {
    metricStats.length > 0 && createChartGraph(metricStats);
  }, [metricStats]);

  return <CanvasContainer>{chartCanvases}</CanvasContainer>;
};
